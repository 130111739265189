import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import AppLayout from "./Layout/AppLayout";
import LogoutPage from "./Pages/LogoutPage";

function App() {
  const auth = useAuth();

  useEffect(() => {
    if (
      !auth.isLoading &&
      !auth.isAuthenticated &&
      location.pathname !== "/logout"
    ) {
      auth.signinRedirect(); // Redirect to Cognito sign-in if not authenticated
    }
  }, [auth.isLoading, auth.isAuthenticated, location.pathname, auth]);

  // Loading state (waiting for the auth process)
  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.isAuthenticated) {
    console.log("User is authenticated", auth.user);
    console.log("User profile:", auth.user.profile);
    const sessionKey =
      "oidc.user:https://cognito-idp.ap-south-1.amazonaws.com/ap-south-1_SM7yRKl6q:7r8e7i5jdmgr5285jab5e95gra";

    const storedData = sessionStorage.getItem(sessionKey);
    let sessionData = JSON.parse(storedData);
    if (sessionData.profile) {
      if (sessionData.profile.email) {
        sessionData.profile.email = sessionData.profile.email.replace(
          /(.{2}).*(@.*)/,
          "$1****$2"
        );
      }
      if (sessionData.profile.phone_number) {
        sessionData.profile.phone_number =
          sessionData.profile.phone_number.replace(
            /(\d{3}).*(\d{3})/,
            "$1****$2"
          );
      }
    }
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionData));
    console.log("After update:", sessionStorage.getItem(sessionKey));
  }

  // Error handling (if authentication encounters an issue)
  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Route for logout */}
        <Route path="/logout" element={<LogoutPage />} />

        {/* Route for main app layout, only accessible if authenticated */}
        <Route
          path="/*"
          element={auth.isAuthenticated ? <AppLayout /> : null}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
