import React, { useEffect, useState } from "react";
import CustomDataGrid from "../components/CustomDataGrid";
import {
  existingUsersGridColumns,
  existingUsersRowMapping,
  generateGridRows,
  generateGridColumns,
} from "../utils/PageUtils";
import axios from "axios";
import { useAuth } from "react-oidc-context";

function ExistingUsers() {
  const APIENDPOINT = "developer19-linqr.reachpersona.com";
  const [error, setError] = useState(null);
  const [existingUsers, setExistingUsers] = useState([]);
  const [usersLength, setUsersLength] = useState(0);
  const auth = useAuth();

  useEffect(() => {
    console.log("nbu");
    const fetchUserCredentialsFromDB = async () => {
      try {
        const response = await axios.get(`https://${APIENDPOINT}/users`, {
          headers: {
            Authorization: `Bearer ${auth?.user?.access_token}`,
          },
        });
        if (response.status === 200) {
          const data = response.data;
          console.log("data", data);
          if (data.count > 0) {
            setUsersLength(data.count);
            setExistingUsers(data.users);
          } else {
            return [];
          }
        } else if (response.status === 404) {
          setError("No user credentials were returned by the API");
          return [];
        } else if (response.status === 500) {
          return [];
        }
      } catch (error) {
        return [];
      }
    };
    fetchUserCredentialsFromDB();
  }, [auth]);

  const existingUsersRows = generateGridRows(
    existingUsers,
    existingUsersRowMapping
  );
  const existingUsersColumns = generateGridColumns(existingUsersGridColumns);
  return (
    <div className="existing-grid">
      <h1 className="user-heading">Existing Users</h1>
      <CustomDataGrid
        rows={existingUsersRows}
        columns={existingUsersColumns}
        totalRows={usersLength}
      />
    </div>
  );
}

export default ExistingUsers;
