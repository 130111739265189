import React, { useEffect } from "react";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import Cookies from "js-cookie";

export default function lamdaTest() {
  const auth = useAuth();
  const BEARER = `Bearer ${auth?.user?.access_token}`;
  console.log("auth", auth);

  console.log("bearer", BEARER);
  // const accessToken = sessionStorage.getItem("access_token");
  // console.log("accessToken", accessToken);
  // sessionStorage.removeItem("access_token");
  // Cookies.set("access_token", accessToken, {
  //   expires: 1,
  //   secure: true,
  //   sameSite: "Strict",
  // });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://5qmt1jvgne.execute-api.ap-south-1.amazonaws.com/lambda-test",
          {
            headers: {
              Authorization: BEARER,
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [BEARER]);

  return (
    <div>
      <h2>icidsnbudsb</h2>
      <h1>lamdaTest</h1>
    </div>
  );
}
