import React from "react";
import { NavLink } from "react-router-dom";
import "../CustomStyle.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/Reach Persona Logo.png";

const Sidebar = ({ isSidebarOpen, toggleSidebar, user }) => {
  const isMobile = window.innerWidth <= 768; // Check for mobile devices
  console.log("User role:", user);
  const handleMenuClick = () => {
    if (isMobile) {
      toggleSidebar();
    }
  };
  return (
    (user === "admin" || user === "customer ") && (
      <div
        style={{
          width: isSidebarOpen ? (isMobile ? "80%" : "22%") : "0",
          height: "100vh",
          backgroundColor: "#000",
          position: "fixed",
          top: 0,
          transition: "width 0.4s ease",
          overflow: "hidden",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "10px",
            left: "10px",
            cursor: "pointer",
            zIndex: 1000,
            color: isSidebarOpen ? "#fff" : "#000",
          }}
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? (
            <CloseIcon fontSize="large" />
          ) : (
            <MenuIcon fontSize="large" />
          )}
        </div>

        {isSidebarOpen && (
          <div className="sidebar-container">
            <div className="logo-container">
              <img src={logo} alt="" />
            </div>
            <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />

            {/* Admin sidebar */}

            {user === "admin" && (
              <div className="list-container">
                <h5>Admin actions</h5>
                <ul>
                  <NavLink
                    to="/admin_create_user"
                    className={({ isActive }) =>
                      isActive ? "linktag active" : "linktag"
                    }
                    onClick={handleMenuClick}
                  >
                    <li>Create a new user</li>
                  </NavLink>
                  <NavLink
                    to="/admin_existing_users"
                    className={({ isActive }) =>
                      isActive ? "linktag active" : "linktag"
                    }
                    onClick={handleMenuClick}
                  >
                    <li>Existing users</li>
                  </NavLink>
                </ul>
              </div>
            )}

            {/* Customers sidebar */}

            {user === "customer" && (
              <div className="list-container">
                <ul>
                  <NavLink
                    to="/create_campaign"
                    className={({ isActive }) =>
                      isActive ? "linktag active" : "linktag"
                    }
                    onClick={handleMenuClick}
                  >
                    <li>Create Campaign</li>
                  </NavLink>
                  <NavLink
                    to="/upload_contacts"
                    className={({ isActive }) =>
                      isActive ? "linktag active" : "linktag"
                    }
                    onClick={handleMenuClick}
                  >
                    <li>Upload Contacts</li>
                  </NavLink>

                  <NavLink
                    to="/proof_template"
                    className={({ isActive }) =>
                      isActive ? "linktag active" : "linktag"
                    }
                    onClick={handleMenuClick}
                  >
                    <li>Proof Template</li>
                  </NavLink>
                  <NavLink
                    to="/generate_print_collaterals"
                    className={({ isActive }) =>
                      isActive ? "linktag active" : "linktag"
                    }
                    onClick={handleMenuClick}
                  >
                    <li>Generate Print Collaterals</li>
                  </NavLink>
                  <NavLink
                    to="/campaign_dashboard"
                    className={({ isActive }) =>
                      isActive ? "linktag active" : "linktag"
                    }
                    onClick={handleMenuClick}
                  >
                    <li>Campaign Dashboard</li>
                  </NavLink>
                  <NavLink
                    to="/lambda_test"
                    className={({ isActive }) =>
                      isActive ? "linktag active" : "linktag"
                    }
                    onClick={handleMenuClick}
                  >
                    <li>Lambda Test</li>
                  </NavLink>
                  <a
                    href="https://blue-support.reachpersona.com/support.html?campaignSource=traqr_cs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linktag "
                    style={{
                      display: "block",
                    }}
                    onClick={handleMenuClick}
                  >
                    <li>Contact Support</li>
                  </a>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default Sidebar;
