import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";

function LogoutPage() {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      auth.removeUser();
      console.log("User is signed out");
    }
  }, [auth.isAuthenticated, auth]);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h2>You have been signed out</h2>
      <p>Thank you for using TraQR.</p>
      <p>
        Click <Link to="/login">here</Link> to sign in again
      </p>
    </div>
  );
}

export default LogoutPage;
