import React, { useState, useEffect, useRef } from "react";
import CustomDataGrid from "../components/CustomDataGrid";
import EntityDetails from "../components/EntityDetails";
import Button from "react-bootstrap/Button";
import "../CustomStyle.css";
import axios from "axios";
import { Alert } from "react-bootstrap";
import JSZip from "jszip";
import QRCode from "qrcode"; // For QR code generation
import { saveAs } from "file-saver"; // For downloading files
import {
  FaTrashAlt,
  FaCheckCircle,
  FaCloudUploadAlt,
  FaDownload,
} from "react-icons/fa";
import {
  campaignGridColumns,
  campaignMapping,
  campaignRowMapping,
  fetchCampaignData,
  generateFormData,
  generateGridColumns,
  generateGridRows,
} from "../utils/PageUtils";
import { useAuth } from "react-oidc-context";

const UploadContacts = () => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignTotalRows, setCampaignTotalRows] = useState(0);
  const [refreshCampaigns, setRefreshCampaigns] = useState(true);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [zipFile, setZipFile] = useState(null);
  const fileInputRef = useRef(null);
  const auth = useAuth();
  const [BEARER, setBearer] = useState(null);
  const APIENDPOINT = "developer19-linqr.reachpersona.com";

  useEffect(() => {
    const fetchData = async () => {
      let bearer = `Bearer ${auth?.user?.access_token}`;
      console.log("before bearer", bearer);
      setBearer(bearer);
      console.log("after bearer", BEARER);
      if (!auth?.user?.access_token) {
        console.warn("No access token available");
        return;
      }
      if (refreshCampaigns) {
        const existingCampaigns = await fetchCampaignData(bearer);
        console.log("campaigns", existingCampaigns);
        if (
          typeof existingCampaigns === "object" &&
          existingCampaigns != null &&
          !Array.isArray(existingCampaigns)
        ) {
          setCampaignsData(existingCampaigns.records);
          setCampaignTotalRows(existingCampaigns.records.length);
        }
        setRefreshCampaigns(false);
      }
    };
    fetchData();
  }, [refreshCampaigns, auth]);

  const campaignFormData = selectedCampaign
    ? generateFormData(selectedCampaign, campaignMapping)
    : null;

  const campaignColumns = generateGridColumns(campaignGridColumns);

  const campaignRows = generateGridRows(campaignsData, campaignRowMapping);

  if (campaignsData.length < 0) {
    return (
      <div
        className="upload-contacts-container"
        style={{
          backgroundColor: "#ff9999 ",
          padding: "10px",
          borderRadius: "10px",
          color: "#ffffff",
        }}
      >
        <h2>No Existing campaigns found for this user. </h2>
      </div>
    );
  }
  function handleClick(e) {
    setSelectedCampaign(null);
    const selected = campaignsData[e.id - 1];
    setTimeout(() => {
      setSelectedCampaign(selected);
    }, 0);
  }

  /* file  upload modal */

  // Reset error message
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];

    setUploadError("");
    setError(null);
    setInfo(null);
    setZipFile(null);
    if (uploadedFile) {
      // Check file type
      if (uploadedFile.type !== "text/csv") {
        setUploadError("Only CSV files are allowed.");
        setFile(null);
        event.target.value = null;
        return;
      }

      // Check file size (2 MB = 2 * 1024 * 1024 bytes)
      if (uploadedFile.size > 2 * 1024 * 1024) {
        setUploadError("File size should not exceed 2 MB.");
        setFile(null);

        event.target.value = null;
        return;
      }

      setFile(uploadedFile);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const data = {
        customer_id: campaignsData[0].customer_id,
        campaign: selectedCampaign.name,
        destination_url: selectedCampaign.destination_url,
      };

      const csvData = await sendToAPI(file, data);
      console.log("response data", csvData);

      if (csvData && Array.isArray(csvData)) {
        const rowsWithId = csvData.map((item) => ({
          ...item,
          id: item.ID, // Map `ID` to `id`
        }));
        const dynamicColumns = Object.keys(csvData[0]).map((key) => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          width: 150,
        }));

        setColumns(dynamicColumns);
        setRows(rowsWithId);
        setTotalRows(csvData.length);
        const trackableUrls = csvData.map((row) => row["o.rp.trackableurl"]);
        generateQRCodesAndZip(csvData, columns, rows);
      }

      setFile(null);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const sendToAPI = async (csvFile, payload) => {
    try {
      console.log("upload contacts");
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      formData.append("file", csvFile);

      const response = await axios.post(
        `https://${APIENDPOINT}/bulk`,

        formData,
        {
          headers: {
            Authorization: BEARER,
            "Content-Type": "multipart/form-data",
          },
          validateStatus: (status) => status <= 500,
        }
      );

      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        setInfo("Data fetched from API successfully!");
        setRefreshCampaigns(true);
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        return data;
      } else if (response.status === 400) {
        setError(response.data.message);
        return;
      } else if (response.status === 500) {
        setError(
          "The operation you attempted has failed. Please contact support."
        );
        return;
      } else {
        setError(
          "The operation you attempted has failed. Please contact support."
        );
        return;
      }
    } catch (error) {
      console.log(error);
      setError(
        "The operation you attempted has failed. Please contact support."
      );
      return;
    }
  };

  const generateQRCodesAndZip = async (csvData) => {
    const zip = new JSZip();
    const qrZip = new JSZip();

    const qrPath = "qrcodes";

    for (const row of csvData) {
      const trackableUrl = row["o.rp.trackableurl"];
      const qrCodeName = row["o.rp.qrcode_img"];

      if (trackableUrl === "Error") {
        continue;
      }
      try {
        const qrCodeDataUrl = await QRCode.toDataURL(trackableUrl, {
          scale: 10,
          margin: 1,
        });
        console.log("qrcode data url", qrCodeDataUrl);
        const qrBlob = await (await fetch(qrCodeDataUrl)).blob();

        qrZip.file(`${qrCodeName}.png`, qrBlob);
      } catch (error) {}
    }

    // Convert CSV data to string and add to ZIP
    const csvContent = csvDataToCsvString(csvData);
    const csvBlob = new Blob([csvContent], { type: "text/csv" });

    zip.file("processed_contacts.csv", csvBlob);

    // Create the QR code zip and add it to the main zip
    const qrZipBlob = await qrZip.generateAsync({ type: "blob" });
    zip.file("qrcodes.zip", qrZipBlob);

    // Generate the ZIP file as a blob
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Generate a formatted file name
    const campaignName = selectedCampaign.name || "unknown";
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "_");
    const zipFileName = `campaign_${campaignName}_bulk_upload_results_${timestamp}.zip`;

    // Save ZIP file to state
    setZipFile({ blob: zipBlob, name: zipFileName });
  };

  const csvDataToCsvString = (data) => {
    const headers = Object.keys(data[0]).join(","); // Get column headers from first row
    const rows = data
      .map((row) =>
        Object.values(row)
          .map((value) => (typeof value === "string" ? value : value)) // Don't add quotes for strings
          .join(",")
      )
      .join("\n");

    return `${headers}\n${rows}`;
  };

  return (
    <div className="upload-contacts-container">
      <>
        <h1 className="main-heading">TraQR-Upload Contacts</h1>

        <h4 className="subheading-1">Select a campaign to upload contacts</h4>
        <p className="upload-para">
          Mandatory CSV columns: ID, envelope_name, greeting_name,
          ma-addr_line1, ma-city, ma-state, ma-zip.
        </p>
        <div className="main-container ">
          <div className="grid-container">
            {/* <h4 className="subheading">Choose a Campaign</h4> */}
            <CustomDataGrid
              rows={campaignRows}
              columns={campaignColumns}
              totalRows={campaignTotalRows}
              onRowClick={handleClick}
            />
          </div>
          {selectedCampaign && (
            <div className="form-container upload-contacts-right">
              <EntityDetails inputs={campaignFormData} />

              <div className="file-upload-container">
                <p>{`Upload CSV file for campaign '${selectedCampaign.name}'`}</p>
                <div className="upload-area">
                  <label htmlFor="fileInput" className="upload-label">
                    <div className="upload-box">
                      <FaCloudUploadAlt size={20} color="black" />
                      <span className="upload-browse-span">Browse</span>
                    </div>
                    <span className="upload-drap-span">or drag and drop</span>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".csv"
                    onChange={handleFileChange}
                    className="file-input"
                    ref={fileInputRef}
                  />
                </div>

                {uploadError && <p className="error-message">{uploadError}</p>}

                {file && (
                  <div className="file-list">
                    <div className="file-item">
                      <span>{file.name}</span>
                      <FaCheckCircle className="icon success" />
                      <FaTrashAlt
                        className="icon delete"
                        onClick={handleRemoveFile}
                      />
                    </div>
                  </div>
                )}

                <Button
                  variant="dark"
                  className="upload-button"
                  onClick={handleUpload}
                  disabled={!file}
                >
                  Upload
                </Button>
              </div>
              {error && (
                <Alert data-testid="error-alert" variant="danger">
                  {error}
                </Alert>
              )}

              {info && (
                <div>
                  <Alert data-testid="success-alert" variant="success">
                    {info}
                  </Alert>
                  <div className="container-csv ">
                    <CustomDataGrid
                      rows={rows}
                      columns={columns}
                      totalRows={totalRows}
                    />
                  </div>
                </div>
              )}

              {zipFile && (
                <Button
                  variant="outline-success"
                  className="upload-button"
                  onClick={() => saveAs(zipFile.blob, zipFile.name)}
                >
                  <FaDownload size={20} style={{ marginRight: "8px" }} />
                  Download CSV and QR Codes
                </Button>
              )}
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default UploadContacts;
