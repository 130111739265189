import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

const cognitoAuthConfig = {
  authority:
    "https://cognito-idp.ap-south-1.amazonaws.com/ap-south-1_SM7yRKl6q",
  client_id: "7r8e7i5jdmgr5285jab5e95gra",
  redirect_uri: "https://new-traqr.reachpersona.com",
  response_type: "code",
  scope: "email openid phone profile",

  onSigninCallback: (_user) => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  // automaticSilentRenew: false, // Disable automatic token refresh
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AuthProvider {...cognitoAuthConfig}>
      <App />
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
