import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Routes, Route } from "react-router-dom";
import CreateCampaign from "../Pages/CreateCampaign";
import CampaignsListGrid from "../Pages/CampaignDashboard";
import ProofTemplate from "../Pages/ProofTemplate";
import UploadContacts from "../Pages/UploadContacts";
import Home from "../Pages/Home.jsx";
import PrintContent from "../Pages/PrintContent.jsx";
import { Alert } from "react-bootstrap";
import CreateNewUser from "../Pages/CreateNewUser.jsx";
import ExistingUsers from "../Pages/ExistingUsers.jsx";
import LamdaTest from "../Pages/lamdaTest.jsx";
import { useAuth } from "react-oidc-context";

const AppLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [secretKey, setSecretKey] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [User, setUserGroup] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const auth = useAuth();
  // let adminKey = "rnEj5G";

  useEffect(() => {
    setAuthError(null);
    if (auth.user?.profile) {
      setUserRole(auth.user.profile["custom:role"]);
      setUserGroup(auth.user.profile["cognito:groups"]);
    }
    console.log("User group:", User);
    console.log("User role:", userRole);
    console.log("User profile:", auth.user.profile);

    // const currentUrl = window.location.href;
    // const hashPart = currentUrl.split("#")[1]?.split("?")[0];
    // const urlSecretKey = hashPart ? hashPart.replace("//$/", "") : "";
    // console.log("secret key", urlSecretKey);
    // const storedSecretKey = sessionStorage.getItem("login_code");

    // if (urlSecretKey) {
    //   setSecretKey(urlSecretKey);
    //   sessionStorage.setItem("login_code", urlSecretKey);
    // } else if (storedSecretKey) {
    //   setSecretKey(storedSecretKey);
    // }
  }, [auth.user]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const signOutRedirect = () => {
    sessionStorage.removeItem("login_code");
    // auth.removeUser();
    const clientId = "7r8e7i5jdmgr5285jab5e95gra";
    const logoutUri = "https://new-traqr.reachpersona.com/logout";
    const cognitoDomain =
      "https://traqr-authentication-poc.auth.ap-south-1.amazoncognito.com";

    const logoutUrl = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(
      logoutUri
    )}`;

    window.location.href = logoutUrl;
  };

  if (authError) {
    return (
      <Alert variant="danger" style={{ width: "80%", margin: "4%" }}>
        {authError}
      </Alert>
    );
  }

  // if (secretKey === adminKey) {
  //   user = "admin";
  // }

  return (
    <>
      {/* Sign-Out Button (Always Displayed) */}
      <div
        style={{ position: "fixed", top: "10px", right: "20px", zIndex: 1000 }}
      >
        <button
          onClick={signOutRedirect}
          style={{
            padding: "10px 20px",
            fontSize: "14px",
            borderRadius: "5px",
            backgroundColor: "#dc3545",
            color: "#fff",
            border: "none",
            cursor: "pointer",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          }}
        >
          Sign Out
        </button>
      </div>

      {userRole ? (
        <div style={{ display: "flex", height: "100vh" }}>
          <div
            style={{
              width: isSidebarOpen
                ? window.innerWidth <= 768
                  ? "70%"
                  : "25%"
                : "0",
              transition: "width 0.3s ease",
            }}
          >
            <Sidebar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              user={userRole || "Unknown"}
            />
          </div>

          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              margin: isSidebarOpen
                ? window.innerWidth <= 768
                  ? "20px 10px 0 10px"
                  : "0 10px 0 10px"
                : "40px 20px 0 20px",
              transition: "margin 0.3s ease",
              width: window.innerWidth <= 768 ? "90%" : "75%",
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/admin_create_user" element={<CreateNewUser />} />
              <Route path="/admin_existing_users" element={<ExistingUsers />} />
              <Route path="/create_campaign" element={<CreateCampaign />} />
              <Route path="/upload_contacts" element={<UploadContacts />} />
              <Route
                path="/campaign_dashboard"
                element={<CampaignsListGrid />}
              />
              <Route path="/proof_template" element={<ProofTemplate />} />
              <Route
                path="/generate_print_collaterals"
                element={<PrintContent />}
              />
              <Route path="/lambda_test" element={<LamdaTest />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/*" element={<Home />} />
        </Routes>
      )}
    </>
  );
};

export default AppLayout;
