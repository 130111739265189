import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const ReusableForm = ({ fields, values, onChange, validationErrors, refs }) => {
  const groupLabels = {
    prospectsQueryParams: "Prospect",
    customerQueryParams: "Realtor",
    campaignQueryParams: "Campaign",
    envelopeCustomerQueryParams: "Realtor's Return Address",
  };

  const groupFields = fields.reduce((acc, field) => {
    if (!acc[field.group]) {
      acc[field.group] = [];
    }
    acc[field.group].push(field);
    return acc;
  }, {});

  return (
    <Form className="campaign-form">
      {Object.entries(groupFields).map(([group, groupFields]) => (
        <fieldset key={group}>
          <legend
            style={{ marginBottom: "2%", fontWeight: "bold", color: "#2f539b" }}
          >
            {groupLabels[group]}
          </legend>

          {groupFields.map((field) => (
            <Form.Group
              controlId={`form-${field.name}`}
              className="form-group-custom"
              key={field.name}
            >
              <div className="modal-form-field">
                <Form.Label className="custom-label">
                  {field.label}
                  {field.required && <span style={{ color: "red" }}> *</span>}
                </Form.Label>
                {field.tooltip && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{field.tooltip}</Tooltip>}
                  >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </OverlayTrigger>
                )}
              </div>
              <Form.Control
                type="text"
                value={values[field.group]?.[field.name] || ""}
                onChange={(e) => onChange(e, field.group, field.name)}
                ref={refs[field.name]}
                maxLength={field.maxLength}
                placeholder={field.placeholder}
              />
              {validationErrors[field.name] && (
                <div style={{ marginTop: "3px" }}>
                  <p style={{ color: "red" }}>{validationErrors[field.name]}</p>
                </div>
              )}
            </Form.Group>
          ))}
        </fieldset>
      ))}
    </Form>
  );
};

export default ReusableForm;
