import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "../CustomStyle.css";
import CustomDataGrid from "../components/CustomDataGrid";
import EntityDetails from "../components/EntityDetails";
import axios from "axios";
import { Spinner, Alert } from "react-bootstrap";
import {
  generateFormData,
  respondedMapping,
  campaignMapping,
  contactVisitsMapping,
  generateGridColumns,
  campaignGridColumns,
  respondedGridColumns,
  contactVisitsGridColumns,
  campaignRowMapping,
  generateGridRows,
  respondedRowMapping,
  contactVisitsRowMapping,
} from "../utils/PageUtils";
import { useAuth } from "react-oidc-context";

export default function CampaignsListGrid() {
  const isInitialRender = useRef(true);
  const isInitialRenderVisit = useRef(true);
  const auth = useAuth();

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignError, setCampaignError] = useState(null);
  const [campaignInfo, setCampaignInfo] = useState(null);
  const [campaignTotalRows, setCampaignTotalRows] = useState(0);

  const [records, setRecords] = useState([]);
  const [respondError, setRespondError] = useState(null);
  const [respondInfo, setRespondInfo] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [campaignName, setCampaignName] = useState(null);
  const [respondedTotalRows, setRespondedTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const [visits, setVisits] = useState([]);
  const [visitError, setVisitError] = useState(null);
  const [visitsTotalRows, setVisitsTotalRows] = useState(0);
  const [selectedVisit, setSelectedVisit] = useState(null);

  const APIENDPOINT = "developer19-linqr.reachpersona.com";

  // fetch campaigns data once and integrate to data grid initially
  useEffect(() => {
    setCampaignError(null);
    setCampaignInfo(null);
    if (!auth?.user?.access_token) {
      console.warn("No access token available");
      return;
    }
    const BEARER = `Bearer ${auth?.user?.access_token}`;

    const fetchCampaignsData = async () => {
      try {
        const response = await axios.get(`https://${APIENDPOINT}/campaigns`, {
          headers: {
            Authorization: BEARER,
          },
        });

        switch (response.status) {
          case 200:
            const data = await response.data;
            setCampaignsData(data.records);
            setCampaignTotalRows(data.records.length);
            break;

          case 400:
            setCampaignError(
              "Something went wrong while attempting to display data on the screen."
            );
            break;

          case 404:
            setCampaignInfo(" No existing campaigns were found for this user.");
            break;

          case 500:
            setCampaignError(
              "Something went wrong while attempting to display data on the screen."
            );
            break;

          default:
            setCampaignError(
              "Something went wrong while attempting to display data on the screen."
            );
        }
      } catch (error) {
        setCampaignError(
          "Something went wrong while attempting to display data on the screen."
        );
      }
    };
    fetchCampaignsData();
  }, [auth]);

  useEffect(() => {
    if (selectedCampaign) {
      setCampaignName(selectedCampaign.name);
      console.log(campaignName, " campaign name");
    }
  }, [selectedCampaign]);

  // fetch responded contacts for a campaign at once and integrate to data grid initially
  useEffect(() => {
    setLoading(true);
    setRespondError(null);
    setRespondInfo(null);
    const fetchRespondedData = async () => {
      try {
        const response = await axios.get(`https://${APIENDPOINT}/records`, {
          headers: {
            Authorization: `Bearer ${auth?.user?.access_token}`,
          },
          params: {
            campaign: campaignName,
          },
          validateStatus: (status) => status >= 200 && status < 500,
        });

        switch (response.status) {
          case 200:
            const data = await response.data;
            setRecords(data);
            setRespondedTotalRows(data.length);
            break;

          case 400:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
            break;

          case 404:
            setRespondInfo("No responses to this campaign yet");
            break;

          case 500:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
            break;

          default:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
        }
      } catch (error) {
        setRespondError(
          "Something went wrong while attempting to display data on to the screen"
        );
      }
      setLoading(false);
    };
    if (isInitialRender.current) {
      isInitialRender.current = false; // Skip the first render
      return;
    }
    fetchRespondedData();
  }, [campaignName]);

  useEffect(() => {
    setLoading(true);
    if (isInitialRenderVisit.current) {
      isInitialRenderVisit.current = false; // Skip the first render
      return;
    }
    console.log("selected contact", selectedContact);

    const fetchContactData = async () => {
      console.log("useEffect triggered by selectedContact:", selectedContact);
      try {
        const qrCodeId = selectedContact.id;
        const response = await axios.get(`https://${APIENDPOINT}/related`, {
          params: {
            path: qrCodeId,
          },
          headers: {
            Authorization: `Bearer ${auth?.user?.access_token}`,
          },
          validateStatus: (status) => status >= 200 && status < 500,
        });

        switch (response.status) {
          case 200:
            const data = await response.data;
            setVisits(data.records);
            console.log("related response", data.records);
            setVisitsTotalRows(data.records.length);
            break;

          case 400:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
            break;

          case 404:
            setRespondInfo("No visits were found for this contact");
            break;

          case 500:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
            break;

          default:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
        }
      } catch (error) {
        return [];
      }
      setLoading(false);
    };

    fetchContactData();
  }, [selectedContact]);

  const campaignFormData = selectedCampaign
    ? generateFormData(selectedCampaign, campaignMapping)
    : null;

  const campaignColumns = generateGridColumns(campaignGridColumns);
  const campaignRows = generateGridRows(campaignsData, campaignRowMapping);

  function handleClick(e) {
    setSelectedCampaign(null);
    const selected = campaignsData[e.id - 1];
    setTimeout(() => {
      setSelectedCampaign(selected);
      setSelectedContact(null);
      setSelectedVisit(null);
    }, 0);
  }

  /*responded contacts logic */

  const respondedFormData = selectedContact
    ? generateFormData(selectedContact, respondedMapping)
    : null;

  const respondedColumns = generateGridColumns(respondedGridColumns);
  const respondedRows = generateGridRows(records, respondedRowMapping);

  // records.map((record, index) => ({
  //   id: index + 1,
  //   fullname: record["greeting_name"],
  //   street: record["ma-addr_line1"],
  // }));

  const handleContactClick = (e) => {
    const selected = records[e.id - 1];
    setSelectedContact(selected);
  };

  /* contact visits logic */

  const visitsFormData = selectedVisit
    ? generateFormData(selectedVisit, contactVisitsMapping)
    : null;

  const visitsColumns = generateGridColumns(contactVisitsGridColumns);
  const visitsRows = generateGridRows(visits, contactVisitsRowMapping);

  const handleVisitClick = (e) => {
    const selected = visits[e.id - 1];
    setSelectedVisit(selected);
  };

  return (
    <div className="dashboard-container">
      <>
        <h1 className="main-heading" data-testid="dashboard-headings">
          TraQR- Campaign Dashboard
        </h1>

        <div className="main-container">
          {campaignInfo && (
            <div>
              <Alert variant="success">{campaignInfo}</Alert>
            </div>
          )}
          {!campaignError && !campaignInfo && (
            <div className="grid-container">
              <h4
                className="subheading"
                data-testid="dashboard-headings"
                style={{ color: "#2f539b" }}
              >
                Click Campaign for Stats
              </h4>
              <CustomDataGrid
                rows={campaignRows}
                columns={campaignColumns}
                totalRows={campaignTotalRows}
                onRowClick={handleClick}
              />
            </div>
          )}
          {selectedCampaign && (
            <div className="form-container">
              <h4 className="subheading" data-testid="dashboard-headings">
                Campaign Details
              </h4>
              <EntityDetails inputs={campaignFormData} />
            </div>
          )}
        </div>
      </>

      {/* Responded Contacts */}

      {selectedCampaign && (
        <div className="main-container">
          {loading && (
            <div className="proof-note">
              <Spinner animation="border" variant="primary" />
              <p>Loading...</p>
            </div>
          )}
          {!loading && respondError && (
            <div className="dashboard-msg">
              <Alert variant="danger">{respondError}</Alert>
              {respondError}
            </div>
          )}
          {!loading && respondInfo && (
            <div className="dashboard-msg">
              <Alert variant="primary" data-testid="respondInfo">
                {respondInfo}
              </Alert>
            </div>
          )}
          {!loading && !respondError && !respondInfo && (
            <div className="grid-container">
              <h4
                className="subheading"
                data-testid="dashboard-headings"
                style={{ color: "#2f539b" }}
              >
                Responded Contacts - Choose One
              </h4>
              <CustomDataGrid
                rows={respondedRows}
                columns={respondedColumns}
                totalRows={respondedTotalRows}
                onRowClick={handleContactClick}
              />
            </div>
          )}

          {respondedFormData && (
            <div className="form-container">
              <h4 className="heading" data-testid="dashboard-headings">
                Contact Details
              </h4>
              <EntityDetails inputs={respondedFormData} />
            </div>
          )}
        </div>
      )}

      {selectedContact && (
        <div className="main-container">
          {loading && (
            <div className="proof-note">
              <Spinner animation="border" variant="primary" />
              <p>Loading...</p>
            </div>
          )}
          {!loading && visitError && (
            <div className="dashboard-msg">
              <Alert variant="danger">{respondError}</Alert>
            </div>
          )}
          {!loading && respondInfo && (
            <div className="dashboard-msg">
              <Alert variant="danfer"> {respondInfo}</Alert>
            </div>
          )}
          {!loading && !respondError && !respondInfo && (
            <div className="grid-container">
              <h4
                className="subheading"
                data-testid="dashboard-headings"
                style={{ color: "#2f539b" }}
              >
                Contact Visits
              </h4>
              <CustomDataGrid
                rows={visitsRows}
                columns={visitsColumns}
                totalRows={visitsTotalRows}
                onRowClick={handleVisitClick}
              />
            </div>
          )}

          {visitsFormData && (
            <div id="form-testId" className="form-container">
              <h4 className="heading" data-testid="dashboard-headings">
                Visit Details
              </h4>
              <EntityDetails inputs={visitsFormData} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
